<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto"> Registration </div>
        <v-tooltip
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              @click="showDialog = true"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Registration</span>
        </v-tooltip>
      <!-- <v-btn small color="success" class="hidden-md-and-down" v-on:click="showDialog = true">
        <v-icon left> mdi-pencil </v-icon>
        New Registration
      </v-btn>
      <v-btn
        class="hidden-lg-and-up"
        v-on:click="showDialog = true"
        fab
        dark
        small
        color="success"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn> -->
    </div>
    <new-registration v-model="showDialog" @fetch="fetchRegistration(), snackbar.active = true"></new-registration>
    <list-item :items="registrationItems" title="id" hasDelete link @delete="deleteItem($event)"></list-item>
    <snack-bar v-model="snackbar.active" :text="snackbar.text"></snack-bar>
  </v-container>
</template>

<script>
import api from '@/services/api'
// @ is an alias to /src
export default {
  name: 'FiwareRegistration',
  components: {
    NewRegistration: () => import('@/components/fiware/NewRegistration'),
    ListItem: () => import('@/components/ListItem'),
    SnackBar: () => import('@/components/SnackBar')
  },
  data: () => ({
    showDialog: false,
    entityItems: [],
    registrationItems: [],
    snackbar: {
      active: false,
      text: null
    }
  }),
  methods: {
    fetchRegistration: function () {
      api.get('/f/v2/registrations/', this.$store.state.fiwareService, null, this.$store.state.tokenRPT).then((response) => {
        // console.log(response.status)
        if (response.status === 200) {
          response.json().then((json) => {
            this.registrationItems = json
            this.snackbar.text = 'created'
          })
        }
      })
    },
    deleteItem: function (itemName) {
      api.delete(`/f/v2/registrations/${itemName}`, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          const rm = this.registrationItems.filter(registration => registration.id !== itemName)
          this.registrationItems = rm
          this.snackbar.active = true
          this.snackbar.text = 'deleted'
        }
      })
    }
  },
  computed: {
    registItems: function () {
      const items = []
      this.registrationItems.forEach(item => {
        items.push({ name: item.id, icon: 'mdi mdi-account-box' })
      })
      return items
    }
  },
  created () {
    // this.fetchEntity()
    this.fetchRegistration()
  }
}
</script>
